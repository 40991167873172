import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { useMemo } from "react";
import { usePureStudentUrls } from "@crehana/auth";
import { getNextUrl } from "@crehana/utils";
import { DEFAULT_MAIN_MENU_URLS, USER_MENU_RENEWAL_ITEM_SOURCE_DETAIL } from "../../constants";
import { createUrlWithAmplitudeSource, createUrlWithCountryPrefix } from "../../utils";
var useMainMenuUrls = function useMainMenuUrls(_ref) {
  var customUrls = _ref.customUrls,
    me = _ref.me,
    countryPrefix = _ref.countryPrefix,
    amplitudePageName = _ref.amplitudePageName;
  var _usePureStudentUrls = usePureStudentUrls({
      // @ts-expect-error Property isActiveDegree is optional in type
      me: _objectSpread(_objectSpread({}, me), {}, {
        organization: me.organization
      })
    }),
    homeUrl = _usePureStudentUrls.homeUrl,
    myCoursesUrl = _usePureStudentUrls.myCoursesUrl,
    myDiplomasUrl = _usePureStudentUrls.myDiplomasUrl,
    myProjectsUrl = _usePureStudentUrls.myProjectsUrl;
  var urls = useMemo(function () {
    var _me$organization, _me$organization2, _me$organization3;
    var nextUrl = getNextUrl();
    var login = nextUrl ? "".concat(DEFAULT_MAIN_MENU_URLS.login, "?next=").concat(nextUrl) : DEFAULT_MAIN_MENU_URLS.login;
    var register = nextUrl ? "".concat(DEFAULT_MAIN_MENU_URLS.register, "?next=").concat(nextUrl) : DEFAULT_MAIN_MENU_URLS.register;
    var home = DEFAULT_MAIN_MENU_URLS.homePage;
    if (!me.isAnonymous || me.isB2B) {
      home = homeUrl;
    }
    var mergedUrls = _objectSpread(_objectSpread(_objectSpread({}, DEFAULT_MAIN_MENU_URLS), {}, {
      login: login,
      register: register,
      home: home,
      premiumLanding: createUrlWithCountryPrefix({
        url: DEFAULT_MAIN_MENU_URLS.premiumLanding,
        countryPrefix: countryPrefix
      }),
      crehanaAcademiesLanding: createUrlWithCountryPrefix({
        url: DEFAULT_MAIN_MENU_URLS.crehanaAcademiesLanding,
        countryPrefix: countryPrefix
      }),
      blog: createUrlWithCountryPrefix({
        url: DEFAULT_MAIN_MENU_URLS.blog,
        countryPrefix: countryPrefix
      }),
      B2BHomeUserKnowledgeHubCatalog: me !== null && me !== void 0 && (_me$organization = me.organization) !== null && _me$organization !== void 0 && _me$organization.slug ? DEFAULT_MAIN_MENU_URLS.createCatalogKnowledgeHubUrl(me.organization.slug) : '',
      B2BAdminDashboard: me !== null && me !== void 0 && (_me$organization2 = me.organization) !== null && _me$organization2 !== void 0 && _me$organization2.slug ? DEFAULT_MAIN_MENU_URLS.createB2BAdminDashboardUrl(me.organization.slug) : '',
      B2BAdminDashboardLeader: me !== null && me !== void 0 && (_me$organization3 = me.organization) !== null && _me$organization3 !== void 0 && _me$organization3.slug ? "".concat(DEFAULT_MAIN_MENU_URLS.createB2BAdminDashboardUrl(me.organization.slug), "lider/") : ''
    }, customUrls), {}, {
      userDashboard: _objectSpread(_objectSpread(_objectSpread({}, DEFAULT_MAIN_MENU_URLS.userDashboard), customUrls === null || customUrls === void 0 ? void 0 : customUrls.userDashboard), {}, {
        diplomas: myDiplomasUrl,
        courses: myCoursesUrl,
        projects: myProjectsUrl
      }),
      appDownload: _objectSpread(_objectSpread({}, DEFAULT_MAIN_MENU_URLS.appDownload), customUrls === null || customUrls === void 0 ? void 0 : customUrls.appDownload),
      renewalLanding: createUrlWithCountryPrefix({
        url: DEFAULT_MAIN_MENU_URLS.renewalLanding,
        countryPrefix: countryPrefix
      }),
      catalog: createUrlWithCountryPrefix({
        url: DEFAULT_MAIN_MENU_URLS.catalog,
        countryPrefix: countryPrefix
      }),
      newCoursesCatalog: createUrlWithCountryPrefix({
        url: DEFAULT_MAIN_MENU_URLS.newCoursesCatalog,
        countryPrefix: countryPrefix
      }),
      searchLanding: createUrlWithCountryPrefix({
        url: DEFAULT_MAIN_MENU_URLS.searchLanding,
        countryPrefix: countryPrefix
      }),
      B2BPlansLanding: createUrlWithCountryPrefix({
        url: DEFAULT_MAIN_MENU_URLS.B2BPlansLanding,
        countryPrefix: countryPrefix
      })
    });
    return _objectSpread(_objectSpread({}, mergedUrls), {}, {
      userDashboard: _objectSpread(_objectSpread({}, mergedUrls.userDashboard), {}, {
        courses: createUrlWithAmplitudeSource({
          url: me.isDegreeStudent ? mergedUrls.userDashboard.microDegree : mergedUrls.userDashboard.courses,
          amplitudePageName: amplitudePageName
        }),
        diplomas: createUrlWithAmplitudeSource({
          url: mergedUrls.userDashboard.diplomas,
          amplitudePageName: amplitudePageName
        }),
        projects: createUrlWithAmplitudeSource({
          url: mergedUrls.userDashboard.projects,
          amplitudePageName: amplitudePageName
        }),
        savedCourses: createUrlWithAmplitudeSource({
          url: mergedUrls.userDashboard.savedCourses,
          amplitudePageName: amplitudePageName
        })
      }),
      login: createUrlWithAmplitudeSource({
        url: mergedUrls.login,
        amplitudePageName: amplitudePageName
      }),
      register: createUrlWithAmplitudeSource({
        url: mergedUrls.register,
        amplitudePageName: amplitudePageName
      }),
      catalog: createUrlWithAmplitudeSource({
        url: mergedUrls.catalog,
        amplitudePageName: amplitudePageName
      }),
      newCoursesCatalog: createUrlWithAmplitudeSource({
        url: mergedUrls.newCoursesCatalog,
        amplitudePageName: amplitudePageName
      }),
      premiumRefer: createUrlWithAmplitudeSource({
        url: mergedUrls.premiumRefer,
        amplitudePageName: amplitudePageName
      }),
      crehanaLive: createUrlWithAmplitudeSource({
        url: mergedUrls.crehanaLive,
        amplitudePageName: amplitudePageName
      }),
      resources: createUrlWithAmplitudeSource({
        url: mergedUrls.resources,
        amplitudePageName: amplitudePageName
      }),
      courseAgenda: createUrlWithAmplitudeSource({
        url: mergedUrls.courseAgenda,
        amplitudePageName: amplitudePageName
      }),
      inbox: createUrlWithAmplitudeSource({
        url: mergedUrls.inbox,
        amplitudePageName: amplitudePageName
      }),
      shoppingCart: createUrlWithAmplitudeSource({
        url: mergedUrls.shoppingCart,
        amplitudePageName: amplitudePageName
      }),
      configuration: createUrlWithAmplitudeSource({
        url: mergedUrls.configuration,
        amplitudePageName: amplitudePageName
      }),
      legal: createUrlWithAmplitudeSource({
        url: mergedUrls.legal,
        amplitudePageName: amplitudePageName
      }),
      premiumLanding: createUrlWithAmplitudeSource({
        url: mergedUrls.premiumLanding,
        amplitudePageName: amplitudePageName
      }),
      crehanaAcademiesLanding: createUrlWithAmplitudeSource({
        url: mergedUrls.crehanaAcademiesLanding,
        amplitudePageName: amplitudePageName
      }),
      blog: createUrlWithAmplitudeSource({
        url: mergedUrls.blog,
        amplitudePageName: amplitudePageName
      }),
      renewalLanding: createUrlWithAmplitudeSource({
        url: mergedUrls.renewalLanding,
        amplitudePageName: amplitudePageName,
        sourceDetail: USER_MENU_RENEWAL_ITEM_SOURCE_DETAIL
      }),
      B2BPlansLanding: createUrlWithAmplitudeSource({
        url: mergedUrls.B2BPlansLanding,
        amplitudePageName: amplitudePageName
      }),
      downloadableResources: createUrlWithAmplitudeSource({
        url: mergedUrls.downloadableResources,
        amplitudePageName: amplitudePageName
      }),
      higherEdLanding: createUrlWithAmplitudeSource({
        url: mergedUrls.higherEdLanding,
        amplitudePageName: amplitudePageName
      })
    });
  }, [customUrls, me, countryPrefix, amplitudePageName, homeUrl, myCoursesUrl, myDiplomasUrl, myProjectsUrl]);
  return urls;
};
export default useMainMenuUrls;